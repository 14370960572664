import './TicketItemDetails.scss';
import clsx from 'clsx';
import moment from 'moment';
import React from 'react';
import { useTranslation } from "react-i18next";
import { Image } from 'react-bootstrap';
import { Order } from '../../core/_models';
import { isOrderTixstock, isOrderXS } from '../../../../services/utils.service';

type Props = {
    order: Order;
    dateIsPassed: boolean;
};

const TicketItemDetails: React.FC<Props> = ({ order, dateIsPassed }: Props) => {
    const { t } = useTranslation();
    const monetaryType = order.itinerary_type_id === "MONETARY_CODE";
    
    return (
        <div className={clsx("order-item-details", { "disabled-text": dateIsPassed })}>
            {order.venue_name && <span>{order.venue_name}</span>}
            <div className="execution-date">
                {!monetaryType && order.execution_start_date && (
                    <span>
                        {moment(order.execution_start_date).format(
                            "DD.MM.YYYY"
                        )}
                    </span>
                )}
                {!monetaryType && order.execution_start_time && (
                    <span>{order.execution_start_time}</span>
                )}
                {monetaryType &&
                    Array.isArray(order.items) &&
                    order.items.length > 0 &&
                    order.items[0].expiry_date && (
                        <span>
                            {t("t:TICKET_LIST.VOUCHER_EXPIRE_DATE", {
                                date: moment(order.items[0].expiry_date).format("DD/MM/YYYY")
                            })}
                        </span>
                    )}
            </div>
            {(isOrderTixstock(order.itinerary_type_id) ||
                isOrderXS(order.itinerary_type_id)) &&
                order.date_confirmed === "N" && !dateIsPassed && (
                    <div className="image-text">
                        <Image
                            src="media/images/order-image-icon.svg"
                            alt="order image icon"
                        />
                        <span>
                            {order.itinerary_type_id === "LIVE_EVENT"
                                ? t("t:TICKET_LIST.NOT_CONCERT_DATE_CONFIRMED")
                                : order.itinerary_type_id === "FORMULA1"
                                    ? t("t:TICKET_LIST.NOT_RACE_DATE_CONFIRMED")
                                    : t("t:TICKET_LIST.NOT_GAME_DATE_CONFIRMED")}
                        </span>
                    </div>
                )}
        </div>
    );
};

export { TicketItemDetails };