export enum OrderStatus {
    Confirmed = 10,
    Pending = 8,
    InProgress = 9,
}

export enum Language {
    HEBREW = "he",
    ENGLISH = "en"
}

export enum Phone {
    COUNTRY_CODE = "country_area_code",
    AREA_CODE = "area_code",
    PHONE_NUMBER = "phone_number"
}

export interface PhoneNumber {
    country_area_code: string;
    area_code: string;
    phone_number: string;
}

export interface OtpResponse {
    success: boolean,
    showResult: boolean,
    status_code: number,
    status_msg: string,
    block_user: string
}

export interface Item {
    age_group_description: string;
    age_group_id: string;
    item_id: number;
    voucher_url: string;
    pax_number: number;
    expiry_date: string;
}

export interface Order {
  order_id: number;
  external_reference: string | null;
  ag_reference: string;
  order_status_id: number;
  order_status_name: string;
  contact_first_name: string;
  contact_last_name: string;
  contact_country_code: string;
  contact_area_code: string;
  contact_phone: string;
  execution_start_date: string;
  execution_start_time: string;
  create_date: string | Date;
  product_name: string;
  product_image_path: string;
  voucher_url: string;
  refundable: string;
  refundable_until: string | null;
  image_clickable: string;
  date_confirmed: string;
  venue_name: string;
  venue_image_text_onclick: string;
  venue_image_text: string;
  venue_ticket_url: string;
  itinerary_type_id: string;
  timezone: string;
  items: Array<Item>;
}

export interface Orders {
    confirmed: Array<Order>,
    pending: Array<Order>,
    pastDate: Array<Order>,     
}

export const otpInitialValues = {
    success: false,
    showResult: false,
    status_code: 0,
    status_msg: '',
    block_user: 'N'
}