import React, {
  useRef,
  useState,
  useEffect,
  useContext,
} from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEventListeners, EventListenerPair } from "../../hooks/useEventListeners";
import IframeResizer from '@iframe-resizer/react'
import useDidMountEffect from "../../hooks/useDidMountEffect";
import externalParamsService, {
  ExternalParams,
} from "../../services/external-params.service";
import { homepageService } from "../../services/homepage.service";
import { LoginStatus } from "../../pages/Homepage/HomePage";
import { EXPECTED_ORIGIN_URL } from "../../utils/constants";
import { UrlUtils } from "../../utils/urlUtils";
import { SiteContext } from "../../store/context/siteContext";
import { storageService } from "../../services/storage.service";

type Props = {
  onLoginStatus: (data: LoginStatus) => void;
};

export const IFrame: React.FC<Props> = () => {
  const [iframeSrc, setIframeSrc] = useState<string>(process.env.REACT_APP_FF_IFRAME_URL);
  const [iframeToken, setIframeToken] = useState(null);
  const [isLoadedToken, setIsLoadedToken] = useState<boolean>(false);
  const [isReady, setIsReady] = useState<boolean>(false);
  const { onLogin, onAuthStart, onHomePageBackground, authState, isAuthStart, languageId } = useContext(SiteContext);
  const iframeRef = useRef<any>();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const eventListeners: EventListenerPair[] = [
    ['message', handleNavigateToFaqPage],
    ['message', handleLoginStatusMessage],
    ['message', handleScrollTopMessage],
    ['message', handleScrollBottomMessage],
    ['message', handleServiceButtonClickMessage],
    ['message', handleReloginMessage],
    ['message', handleScrollToElement],
    ['message', handleScrollTo],
    ['message', handleCurrencyChange],
    ['message', handleDestinationPickerMessage],
    ['message', handleNavigateToLocateTickets]
  ];

  useEventListeners(eventListeners);

  useDidMountEffect(() => {
    if (!authState.isAuthenticated) {
      if (iframeRef.current) {
        iframeRef.current.sendMessage({ message: "logout" }, "*");
      }

      fetchIframeToken();
    }
  }, [authState.isAuthenticated]);

  useEffect(() => {
    if (isReady) {
      if (!iframeRef.current) return;

      if (iframeRef.current && isAuthStart) {
        iframeRef.current.sendMessage({ message: "login" }, "*");
        onAuthStart(false);
      }
    }
  }, [iframeRef, isAuthStart, onAuthStart, isReady]);

  useEffect(() => {
    const params = new URLSearchParams(process.env.REACT_APP_FF_IFRAME_URL)
    const currentLangId = params.get(ExternalParams.LanguageId);
    if (currentLangId !== languageId) {
      setIframeSrc((prevSrc) => {
        return prevSrc.replace(currentLangId, languageId);
      });
    }
  }, [languageId]);

  useEffect(() => {
    const savedExternalToken = externalParamsService.getExternalToken();
    const externalToken = UrlUtils.getQueryParam(ExternalParams.UserToken);
    const extractedToken = externalToken ? externalToken : savedExternalToken;
    if (extractedToken && !savedExternalToken) externalParamsService.setExternalToken(extractedToken);

    if (!extractedToken) {
      fetchIframeToken();
    } else {
      setIsLoadedToken(true);
      setIframeToken(extractedToken);
    }
  }, []);

  useEffect(() => {
    const savedUtms = externalParamsService.getUtms();
    const externalUtms = UrlUtils.mapUTMQueryToParams();
    const extractedUtms = externalUtms ? externalUtms : savedUtms;
    if (extractedUtms && !savedUtms) externalParamsService.setUtms(externalUtms);
  }, []);

  async function fetchIframeToken() {
    const response = await homepageService.getSiteIframeToken();
    setIsLoadedToken(true);
    setIframeToken(response);
  }

  const onload = (): void => {
    iframeRef.current.resize();
  };

  const handleReady = (): void => setIsReady(true);

  function handleNavigateToFaqPage(event: MessageEvent) {
    if (event.data === "faq" && event.origin === EXPECTED_ORIGIN_URL) {
      navigate('/questions');
    }
  }

  function handleScrollToElement(event: MessageEvent): void {
    if (event.data.message === "scrollToElement" && event.origin === EXPECTED_ORIGIN_URL) {
      const elementId = event.data.value;
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }

  function handleScrollTo(event: MessageEvent): void {
    if (event.data.message === "scrollTo" && event.origin === EXPECTED_ORIGIN_URL) {
      window.scrollTo(0, event.data.value)
    }
  }

  function handleLoginStatusMessage(event: MessageEvent) {
    if (event.data.message === "loginStatus" && event.origin === EXPECTED_ORIGIN_URL) {
      if (event.data.value?.name && event.data.value?.name.length > 0 && event.data.value?.hasOwnProperty("isSuccess") && event.data.value.isSuccess) {
        onLogin({ userName: event.data.value?.name, isAuthenticated: true });
        if (event.data.value?.hasOwnProperty("token") && event.data.value.token?.length > 0) {
          externalParamsService.setExternalToken(event.data.value.token);
        }
      }
    }
  }

  function handleScrollTopMessage(event: MessageEvent) {
    if (event.data === "scrollTop" && event.origin === EXPECTED_ORIGIN_URL) {
      window.scrollTo(0, 0);
    }
  }

  function handleReloginMessage(event: MessageEvent) {
    if (event.data.message === "relogin" && event.origin === EXPECTED_ORIGIN_URL) {
      onLogin({ userName: "", isAuthenticated: false });
      externalParamsService.removeAuth();
      externalParamsService.removeExternalToken();
    }
  }

  function handleScrollBottomMessage(event: MessageEvent) {
    if (event.data === "scrollBottom" && event.origin === EXPECTED_ORIGIN_URL) {
      window.scrollTo(0, window.outerHeight + 200);
    }
  }

  function handleServiceButtonClickMessage(event: MessageEvent) {
    if (
      event.data === "customerService" &&
      event.origin === EXPECTED_ORIGIN_URL
    ) {
      const url =
        process.env.REACT_APP_CUSTOMER_SERVICE_URL +
        t("t:CUSTOMER_SERVICE.SUPPORT_TEXT");

      window.location.replace(url);
    }
  }

  function handleCurrencyChange(event: MessageEvent) {
    if (event.data.message === "currencyChange") {
      storageService.setCurrencyId(event.data.value)
    }
  }

  function handleDestinationPickerMessage(event: MessageEvent) {
    if (event.data === "destinations-picker-open") {
      onHomePageBackground(true);
    }
    if (event.data === "destinations-picker-closed") {
      onHomePageBackground(false);
    }
  }

  function handleNavigateToLocateTickets(event: MessageEvent) {
    if (event.data.message === 'MY_ORDERS') {
      navigate('/locate_tickets');
    }
  }

  const getIframeSrc = () => {
    const extractedParams = UrlUtils.mapQueryToParams();
    const savedUtms = externalParamsService.getUtms();
    const externalUtms = UrlUtils.mapUTMQueryToParams();
    const extractedUtms = externalUtms || savedUtms;
    const currencyId = storageService.getCurrencyId();
    const searchParams = new URLSearchParams({
      token: iframeToken,
      ...extractedUtms,
      ...extractedParams,
      ...(currencyId ? { currency_id: currencyId } : {}),
    });

    return `${iframeSrc}&${searchParams.toString()}`;
  };

  return (
    <>
      {isLoadedToken && (
        <IframeResizer
          license={process.env.REACT_APP_IFRAME_RESIZER_LICENSE || "GPLv3"} // GPLv3 Open Source License
          forwardRef={iframeRef}
          inPageLinks
          onLoad={onload}
          onReady={handleReady}
          src={getIframeSrc()}
          style={{ width: "1px", minWidth: "100%" }}
          allow="clipboard-read; clipboard-write"
        />
      )}
    </>
  );
};
