import './CustomTooltip.scss';
import React from 'react';
import { OverlayTrigger, Tooltip, TooltipProps } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

type Placement = 'auto-start' | 'auto' | 'auto-end' | 'top-start' | 'top' | 'top-end' | 'right-start' | 'right' | 'right-end' | 'bottom-end' | 'bottom' | 'bottom-start' | 'left-end' | 'left' | 'left-start';
type Trigger = 'hover' | 'click' | 'focus' | Array<'hover' | 'click' | 'focus'>;

type Props = {
  children: React.ReactNode;
  text: string;
  trigger?: Trigger;
  delay?: {
    show: number;
    hide: number;
  };
  placement?: Placement;
  isTranslated?: boolean;
};

const CustomTooltip: React.FC<Props> = ({
  children,
  text,
  trigger = 'hover',
  delay = { show: 250, hide: 400 },
  placement = "bottom",
  isTranslated = false,
}: Props) => {
  const { t } = useTranslation();

  const renderTooltip = (props: TooltipProps) => (
    <Tooltip id="tooltip-primary" className='tooltip-primary' {...props}>
      {isTranslated ? text : t(`t:${text}`)}
    </Tooltip>
  );

  const popperConfig = placement === 'bottom-end'
    ? { modifiers: [{ name: 'offset', options: { offset: [-40, 7] } }] }
    : {};

  if (!text) {
    return <span className="overlay-trigger-container">{children}</span>;
  }

  return (
    <OverlayTrigger
      placement={placement}
      trigger={trigger}
      delay={delay}
      overlay={renderTooltip}
      popperConfig={popperConfig}
    >
      <span className='overlay-trigger-container'>{children}</span>
    </OverlayTrigger>
  );
};

export { CustomTooltip };