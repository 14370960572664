import './TicketsList.scss'
import '../../LocateTicket.scss';
import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from 'react-i18next';
import { Header } from "../../../../components/Header/Header";
import { Footer } from "../../../../components/Footer/Footer";
import { Button, Card, Col, Image, Row, Accordion } from "react-bootstrap";
import { orderService } from "../../../../services/order.service";
import { useLocation } from "react-router-dom";
import { PhoneNumber, Orders, Order, Item } from '../../core/_models';
import CustomModal from "../../../../components/CustomModal/CustomModal";
import { CustomerServiceButton } from "../../../../components/CustomerServiceBtn/CustomerServiceButton";
import { IdleSession } from "../../../../components/IdleSession/IdleSession";
import { SessionExpired } from "../../../../components/SessionExpired/SessionExpired";
import OvalLoader from "../../../../components/Loader/OvalLoader";
import { PageLink } from "../../../../models/bredcrumbs.model";
import { CustomDrawer } from "../../../../components/Drawer/CustomDrawer";
import ErrorPage from "../../../ErrorPage/ErrorPage";
import { NoTicketsFound } from "../NoTicketsFound/NoTicketsFound";
import { DownloadTicket } from "../DownloadTicket/DownloadTicket";
import { TicketItem } from '../TicketItem/TicketItem';
import { isOrderXS } from "../../../../services/utils.service";

const initialOrders: Orders = {
    confirmed: [],
    pending: [],
    pastDate: [],
};

const initialDownloadState: {
    show: boolean; 
    order: Order | null; 
  } = {show: false, order: null}

export const TicketsList = () => {
    const [mobile, setMobile] = useState(window.innerWidth <= 576);
    const [showDownloadModal, setShowDownloadModal] = useState(initialDownloadState);
    const [orders, setOrders] = useState(initialOrders);
    const [total, setTotal] = useState<number>(0);
    const [cancelOrderID, setCancelOrderID] = useState<number | null>(null);
    const [currentOrder, setCurrentOrder] = useState<number | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [showResponseModal, setShowResponseModal] = useState<boolean>(false);
    const [showResponseDrawer, setShowResponseDrawer] = useState<boolean>(false);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [actionResponse, setActionResponse] = useState({ success: false, title: '', msg: '', drawer_title: "" })
    const [apiError, setApiError] = useState<boolean>(false);
    const { state } = useLocation();
    const { t } = useTranslation();

    const breadcrumbs: Array<PageLink> = useMemo(() => [
        {
            title: t("t:HEADER.HOMEPAGE"),
            path: '/',
            isSeparator: true,
            isActive: false,
        },
        {
            title: t("t:LOCATE_TICKETS.TITLE"),
            path: '/locate_tickets',
            isSeparator: false,
            isActive: true,
        }
    ], [t]);

    const handleWindowSizeChange = () => {
        setMobile(window.innerWidth <= 576);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);


    const handleDrawerToggle = () => {
        setShowResponseDrawer(!showResponseDrawer);
    };

    const handleCloseResponseModal = () => setShowResponseModal(false);

    const getOrders = async (phoneNumber: PhoneNumber) => {
        try {
            const response = await orderService.getTicketsList(phoneNumber);
            setOrders(response.orders);
            setTotal(response.total);
        } catch (error) {
            setApiError(true);
        }
    }

    useEffect(() => {
        if (state) {
            setLoading(true);
            getOrders(state?.phone_number).then(() => {
                setLoading(false);
            });
        }
    }, [state]);

    const handleCloseCancelModal = () => setShowCancelModal(false);

    const handleShowCancelModal = (ev: React.MouseEvent, orderID: number) => {
        ev.stopPropagation();
        setCancelOrderID(orderID);
        setShowCancelModal(true);
    };

    const handleShowResponse = (value: boolean) => {
        if (mobile) {
            setShowResponseDrawer(value);
        } else {
            setShowResponseModal(value)
        }
    };

    const handleCancelOrder = async () => {
        const drawerTitle = t("t:TICKETS_LIST.CANCEL_ORDER_DRAWER_TITLE");
        const errMsg = {
            success: false,
            title: t("t:TICKETS_LIST.CANCEL_ORDER_ERROR_TITLE"),
            msg: t("t:TICKETS_LIST.CANCEL_ORDER_ERROR_TEXT"),
            drawer_title: drawerTitle
        };
        try {
            setLoading(true);
            setShowCancelModal(false);
            await orderService.cancelOrder(Number(cancelOrderID));
            if (total === 1) {
              setOrders(initialOrders);
              setTotal(0);
            } else {
              await getOrders(state?.phone_number);
            }
            setActionResponse({
                success: true,
                title: t("t:TICKETS_LIST.CANCEL_ORDER_SUCCESS_TITLE"),
                msg: t("t:TICKETS_LIST.CANCEL_ORDER_SUCCESS_TEXT"),
                drawer_title: drawerTitle
            })
            handleShowResponse(true);
            return;
        } catch (error) {
            setActionResponse(errMsg);
            handleShowResponse(true);
            setApiError(true);
        } finally {
            setLoading(false);
            setCancelOrderID(null);
        }
    }

    const handleSendEmail = async (ev: React.MouseEvent, orderID: number) => {
        ev.stopPropagation();
        setCurrentOrder(orderID);
        const drawerTitle = t("t:TICKETS_LIST.SEND_EMAIL_DRAWER_TITLE");
        const errMsg = {
            success: false,
            title: t("t:TICKETS_LIST.SEND_EMAIL_ERROR_TITLE"),
            msg: t("t:TICKETS_LIST.SEND_EMAIL_ERROR_TEXT"),
            drawer_title: drawerTitle
        }
        try {
            setLoading(true);
            await orderService.sendEmail(orderID as number);
            setActionResponse({
                success: true,
                title: t("t:TICKETS_LIST.SEND_EMAIL_SUCCESS_TITLE"),
                msg: t("t:TICKETS_LIST.SEND_EMAIL_SUCCESS_TEXT"),
                drawer_title: drawerTitle
            })
            handleShowResponse(true);
            return;
        } catch (error) {
            setActionResponse(errMsg);
            handleShowResponse(true);
            setApiError(true);
        } finally {
            setLoading(false);
            setCurrentOrder(null);
        }
    }

    const handleOrderClick = (order: any) => {
        const {
            itinerary_type_id,
            order_status_id,
            venue_ticket_url,
            voucher_url,
            image_clickable,
            items,
        } = order;
        if (isOrderXS(itinerary_type_id) && order_status_id === 10 && image_clickable === "Y") {
            window.open(venue_ticket_url);
            return;
        }

        if ((!isOrderXS(itinerary_type_id) && itinerary_type_id !== "MONETARY_CODE") && order_status_id === 10) {
            if (voucher_url !== null && voucher_url.trim().length > 0) {
                window.open(voucher_url);
                return;
            } 

            if (items.length === 1 && items[0].voucher_url !== null) {
                const voucherUrl = items[0].voucher_url;
                window.open(voucherUrl);
                return;
            }
            if (items.length > 1) {
                setShowDownloadModal({
                    show: true,
                    order: order,
                });
            }
        }

        return;
    };

    const handleCloseDownloadModal = () => setShowDownloadModal(initialDownloadState);

    const cancelOrderModal = <Row className="custom-modal-body">
        <Row className="text-center">
            <Image className="error-icon text-center" src='media/images/errorIcon.svg' alt="error-icon" />
            <span className="title cancel-title">{t("t:TICKETS_LIST.CANCEL_ORDER_TITLE")}</span>
            <span className="confirm-text">{t("t:TICKETS_LIST.CANCEL_ORDER_CONFIRM")}</span>
            <Row className='d-flex justify-content-center gap-4'>
                <Button variant="primary" className="cancel-order-btn w-100" aria-label={t("t:TICKETS_LIST.CANCEL_ORDER_BTN_OK_ARIA_LABEL")}
                    onClick={handleCancelOrder}>{t("t:TICKETS_LIST.CANCEL_ORDER_OK")}</Button>
                <Button className="close-modal-btn w-100" aria-label={t("t:TICKETS_LIST.CANCEL_ORDER_BTN_CANCEL_ARIA_LABEL")}
                    onClick={handleCloseCancelModal}>{t("t:TICKETS_LIST.CANCEL_ORDER_CANCEL")}</Button>
            </Row>
        </Row>
    </Row>

    const responseModal = <Row className="custom-modal-body">
        <Row className="text-center">
            <Image className="error-icon text-center"
                src={actionResponse.success ? 'media/images/successIcon.svg' : 'media/images/errorIcon.svg'} />
            <span className="title response-title"> {actionResponse.title}</span>
            <span className="confirm-text">
                {actionResponse.msg}
            </span>
            <Row className='justify-content-center'>
                {actionResponse.success ?
                    <Button 
                        className="close-modal-btn" 
                        aria-label={t("t:TICKETS_LIST.CLOSE_MODAL_ARIA_LABEL")} 
                        onClick={handleCloseResponseModal}
                    >
                        {t("t:TICKETS_LIST.CLOSE_MODAL")}
                    </Button> :
                    <CustomerServiceButton />}
            </Row>
        </Row>
    </Row>


    const noOrdersFound = <Row className="locate-tickets-container">
        <Col md={8} lg={6} xl={5}>
            <Card className="locate-ticket-card border-0 mx-lg-4">
                <Card.Body className="px-lg-5 py-5">
                    <NoTicketsFound redirectUrl="/locate_tickets" />
                </Card.Body>
            </Card>
        </Col>
        <Col md={8} lg={6} xl={6}>
            <Image src='media/images/THG_M83_04.png' className="side-image w-100 h-100" />
        </Col>
    </Row>

    const downloadModal = (
      <Row className="text-center custom-modal-body">
        <span className="title">
          {t("t:TICKET_LIST.DOWNLOAD_VOUCHERS")}
        </span>
            <div className="download-content">
                {showDownloadModal.order !== null && showDownloadModal.order.items?.length > 0 &&
                    showDownloadModal.order.items.map((item: Item, index: number) => (
                        <DownloadTicket
                            key={item.item_id}
                            item={item}
                            order={showDownloadModal.order}
                            ticketNumber={index + 1}
                        />
                    ))}
            </div>
          <Col className="col-6 w-100 d-flex justify-content-center">
            <Button
              variant="primary"
              className="download-modal-btn"
              onClick={handleCloseDownloadModal}
            >
              {t("t:TICKETS_LIST.CLOSE_MODAL")}
            </Button>
          </Col>        
      </Row>
    );


    return (
        <>
            <OvalLoader className="overlayWrapper" isLoading={loading} />
            <Header breadcrumbs={breadcrumbs}></Header>
            <IdleSession />
            <SessionExpired>
                <div className="container orders-container">
                    {apiError && <ErrorPage error_code={500} />}
                    {!loading && !apiError && (
                        <>
                            {total === 0 ? noOrdersFound :
                                <>
                                    <Row>
                                        <h1 className="content-title">{t("t:LOCATE_TICKETS.TITLE")}</h1>
                                    </Row>
                                    <Row className="accordions-container">
                                        {orders.confirmed.length > 0 && (
                                            <Accordion bsPrefix="accordion" defaultActiveKey='1'>
                                                <Accordion.Item eventKey='1'>
                                                    <Accordion.Header bsPrefix="accordion-header">
                                                        {t("t:ORDERS.TYPE_CONFIRMED_HEADER")}
                                                    </Accordion.Header>
                                                    <Accordion.Body bsPrefix="accordion-body">
                                                        <Row className="cards-container">
                                                            <TicketItem
                                                                orders={orders.confirmed}
                                                                onOrderClick={handleOrderClick}
                                                                onSendEmail={handleSendEmail}
                                                                onShowCancelModal={handleShowCancelModal}
                                                                currentOrder={currentOrder}
                                                                cancelOrderID={cancelOrderID}
                                                                loading={loading}
                                                            />
                                                        </Row>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        )}
                                        {orders.pending.length > 0 && (
                                            <Accordion bsPrefix="accordion">
                                                <Accordion.Item eventKey='2'>
                                                    <Accordion.Header bsPrefix="accordion-header">
                                                        {t("t:ORDERS.TYPE_PENDING_HEADER")}
                                                    </Accordion.Header>
                                                    <Accordion.Body bsPrefix="accordion-body">
                                                        <Row className="cards-container">
                                                            <TicketItem
                                                                orders={orders.pending}
                                                                onOrderClick={handleOrderClick}
                                                                onSendEmail={handleSendEmail}
                                                                onShowCancelModal={handleShowCancelModal}
                                                                currentOrder={currentOrder}
                                                                cancelOrderID={cancelOrderID}
                                                                loading={loading}
                                                            />
                                                        </Row>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        )}
                                        {orders.pastDate.length > 0 && (
                                            <Accordion bsPrefix="accordion">
                                                <Accordion.Item eventKey='3'>
                                                    <Accordion.Header bsPrefix="accordion-header">
                                                        {t("t:ORDERS.TYPE_PAST_HEADER")}
                                                    </Accordion.Header>
                                                    <Accordion.Body bsPrefix="accordion-body">
                                                        <Row className="cards-container">
                                                            <TicketItem
                                                                orders={orders.pastDate}
                                                                onOrderClick={handleOrderClick}
                                                                onSendEmail={handleSendEmail}
                                                                onShowCancelModal={handleShowCancelModal}
                                                                currentOrder={currentOrder}
                                                                cancelOrderID={cancelOrderID}
                                                                loading={loading}
                                                            />
                                                        </Row>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        )}
                                    </Row>
                                </>
                            }
                        </>
                    )}

                </div>
                <CustomModal
                    formID="cancel-order"
                    modalBody={cancelOrderModal}
                    show={showCancelModal}
                    handleClose={handleCloseCancelModal}
                />
                <CustomModal
                    formID="response-modal"
                    modalBody={responseModal}
                    show={showResponseModal}
                    handleClose={handleCloseResponseModal}
                />
                <CustomModal
                    formID="download-modal"
                    dialogClassName="download-modal"
                    modalBody={downloadModal}
                    show={showDownloadModal.show}
                    handleClose={handleCloseDownloadModal}
                />
            </SessionExpired>
            <CustomDrawer
                show={showResponseDrawer}
                actionResponse={actionResponse}
                handleDrawerToggle={handleDrawerToggle} />
            <Footer />
        </>
    );
}