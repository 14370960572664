import "./TicketItem.scss";
import moment from "moment";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import { useWindowDimensions } from '../../../../hooks/useWindowDemensions';
import { Col, Card } from "react-bootstrap";
import { orderDateIsPast, isOrderClickable } from "../../../../services/utils.service";
import { Order } from "../../core/_models";
import { CardButton } from '../../../../components/CardButton';
import { CustomTooltip } from '../../../../components/CustomTooltip';
import { RefundDetails } from '../RefundDetails';
import { TicketItemDetails } from '../TicketItemDetails';

type Props = {
  orders: Array<Order>;
  onOrderClick: (order: Order) => void;
  onSendEmail: (ev: React.MouseEvent, orderID: number) => void;
  onShowCancelModal: (ev: React.MouseEvent, orderID: number) => void;
  currentOrder: number | null;
  cancelOrderID: number | null;
  loading: boolean;
};

const TicketItem: React.FC<Props> = ({
  orders,
  onOrderClick,
  onSendEmail,
  onShowCancelModal,
  currentOrder,
  cancelOrderID,
  loading,
}) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  return (
    <>
      {orders.map((order: Order) => {
        const datePassed = orderDateIsPast(
          order.execution_start_date,
          order.execution_start_time,
          order.timezone
        );
        const orderIsPending =
          order.order_status_id === 8 || order.order_status_id === 9;
        const orderIsConfirmed = order.order_status_id === 10;
        const orderIsCancelable = order.refundable === "Y";
        const imageIsClickable = isOrderClickable(order);

        const emailIsSending = loading && currentOrder === order.order_id;
        const orderIsCanceling = loading && cancelOrderID === order.order_id;

        return (
          <Col
            sm={12}
            md={6}
            lg={6}
            xl={4}
            xxl={3}
            key={order.order_id}
            className="card-col"
          >
            <div
              aria-label={t("t:TICKETS_LIST.ITEM_ARIA_LABEL", {
                order: order.product_name,
              })}

              className={clsx("card-container", {
                "disabled-order": datePassed,
                "image-clickable": imageIsClickable,
              })}
              onClick={() => onOrderClick(order)}
            >
              <Card>
                <div className="card-image-container">
                  <Card.Img
                    variant="top"
                    className={clsx({ "disabled-img": datePassed })}
                    src={order.product_image_path}
                  />
                  {order.create_date && (
                    <span className="purchase-date">
                      {`${t("t:TICKET_LIST.PURCHASE_DATE")} ${moment(
                        order.create_date
                      ).format("DD/MM/YYYY")}`}
                    </span>
                  )}
                </div>
                <Card.Body>
                  <Card.Title className={clsx({ "disabled-text": datePassed })}>
                    {order.product_name}
                  </Card.Title>
                  <Card.Text className={clsx({ "disabled-text": datePassed })}>
                    <TicketItemDetails order={order} dateIsPassed={datePassed} />
                    <RefundDetails order={order} />
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <div className="confirmed-btn-container">
                    {orderIsConfirmed && !datePassed && (
                      <CardButton
                        variant="email"
                        ariaLabel="TICKETS_LIST.SEND_EMAIL_BTN_ARIA_LABEL"
                        disabled={emailIsSending}
                        onClick={(ev) => onSendEmail(ev, order.order_id)}
                        title={
                          emailIsSending
                            ? "TICKETS_LIST.SEND_EMAIL_LOADING"
                            : "TICKETS_LIST.SEND_EMAIL_BTN"
                        }
                      />
                    )}

                    {orderIsPending && !datePassed && (
                      <CustomTooltip
                        text={order.venue_image_text_onclick}
                        trigger={width < 767 ? 'click' : ['hover', 'click']}
                        placement={orderIsCancelable ? 'bottom-end' : 'bottom'}
                        isTranslated={true}
                      >
                        <CardButton
                          variant="pending"
                          title="TICKETS_LIST.ORDER_PENDING_STATUS"
                        />
                      </CustomTooltip>
                    )}

                    {orderIsCancelable && (
                      <CardButton
                        variant="cancel"
                        ariaLabel="TICKETS_LIST.CANCEL_ORDER_BTN_ARIA_LABEL"
                        disabled={orderIsCanceling}
                        onClick={(ev) => onShowCancelModal(ev, order.order_id)}
                        title={
                          orderIsCanceling
                            ? "TICKETS_LIST.CANCEL_ORDER_LOADING"
                            : "TICKETS_LIST.CANCEL_ORDER_BTN"
                        }
                      />
                    )}
                  </div>
                </Card.Footer>
              </Card>
            </div>
          </Col>
        );
      })}
    </>
  );
};

export { TicketItem };