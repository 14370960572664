import './RefundDetails.scss';
import moment from 'moment-timezone';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Order } from '../../core/_models';
import { getTimezoneCity } from '../../../../services/utils.service';

type Props = {
    order: Order;
};

const RefundDetails: React.FC<Props> = ({ order }: Props) => {
    const { t } = useTranslation();

    return (
        <>
            {order.refundable === "Y" && order.refundable_until && order.timezone ? (
                <div className="refund-details">
                    <span>
                        {t("t:TICKET.REFUND_UNTIL", {
                            refundUntil: moment.tz(order.refundable_until, order.timezone).format('HH:mm DD.MM.YY'),
                        })}
                    </span>
                    <span>{" | "}</span>
                    <span>
                        {t("t:TICKET.REFUND_UNTIL_TIMEZONE", { timezone: getTimezoneCity(order.timezone) })}
                    </span>
                </div>
            ) : (
                <div className="refund-details-fallback" />
            )}
        </>
    )
};

export { RefundDetails };