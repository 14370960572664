import clsx from "clsx";
import React, { useState, useContext } from "react";
import { Header } from "../../components/Header/Header";
import { Footer } from "../../components/Footer/Footer";
import { IFrame } from "../../components/IFrame/IFrame";
import { SiteContext, SiteSettings } from '../../store/context/siteContext';
import "./HomePage.scss";

export type LoginStatus = {
  isSuccsess: boolean;
  status_code: number;
  status_message: string;
  name: string;
};

const HomePage = () => {
  const [loginStatus, setLoginStatus] = useState<LoginStatus>();
  const { showDestinationPicker } = useContext(SiteContext) as SiteSettings;

  const handleLoginStatus = (statusData: LoginStatus) => {
    setLoginStatus(statusData);
  };

  return (
    <>
      <Header
        breadcrumbs={[]}
        loginStatus={loginStatus}
      />
      <div className={clsx('homepage-content-container', {
        'destinations-background': showDestinationPicker
      })}>
        <IFrame onLoginStatus={handleLoginStatus} />
      </div>
      <Footer />
    </>
  );
};

export default HomePage;
